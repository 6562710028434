<template>
  <nav class="table verticle-middle mt-lg-4 ml-lg-0" aria-label="Pagination">
    <div
      class="pagination-item"
      @click="onClickFirstPage"
      :disabled="isInFirstPage"
    >
      <span class="">First&nbsp;</span>
    </div>
    <div
      class="pagination-item"
      @click="onClickPreviousPage"
      :disabled="isInFirstPage"
    >
      <span class="">&nbsp;Previous&nbsp;</span>
    </div>
    <!-- Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" -->
    <div v-for="page in pages" :key="page" class="pagination-item">
      <div
        class="border text-sm font-medium"
        :class="{ active: isPageActive(page.name) }"
        @click="onClickPage(page.name)"
      >
        <span :disabled="page.isDisabled"> {{ page.name }} </span>
      </div>
    </div>
    <div @click="onClickNextPage" class="pagination-item">
      <span class="inline-block">&nbsp;Next&nbsp;</span>
    </div>
    <div
      @click="onClickLastPage"
      class="pagination-item"
      v-if="this.currentPage != this.totalPages"
    >
      <span class="inline-block">&nbsp;Last</span>
    </div>
    <div class="pagination-item" v-if="this.currentPage == this.totalPages">
      <span class="inline-block">&nbsp;Last</span>
    </div>
  </nav>
</template>
<script>
export default {
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 2,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  computed: {
    startPage() {
      // When on the first page
      if (this.currentPage === 1) {
        return 1;
      }

      // When on the last page
      if (this.currentPage === this.totalPages) {
        return this.totalPages - this.maxVisibleButtons;
      }

      // When inbetween
      return this.currentPage - 1;
    },
    pages() {
      const range = [];
      for (
        let i = this.startPage;
        i <= Math.min(this.startPage + this.maxVisibleButtons, this.totalPages);
        i++
      ) {
        if (i > 0) {
          range.push({
            name: i,
            isDisabled: i === this.currentPage,
          });
        }
      }

      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
  },
  methods: {
    isPageActive(page) {
      return this.currentPage === page;
    },
    onClickFirstPage() {
      this.$emit("pagechanged", 1);
    },
    onClickPreviousPage() {
      if (this.currentPage > 1) {
        this.$emit("pagechanged", this.currentPage - 1);
      }
    },
    onClickPage(page) {
      if (this.currentPage >= 1) {
        this.$emit("pagechanged", page);
      }
    },
    onClickNextPage() {
      if (this.currentPage + 1 <= this.totalPages) {
        this.$emit("pagechanged", this.currentPage + 1);
      }
    },
    onClickLastPage() {
      this.$emit("pagechanged", this.totalPages);
    },
  },
};
</script>

<style scoped>
.pagination {
  list-style-type: none;
}

.pagination-item {
  display: inline-block;
}

.active {
  background-color: #1e38a7 !important;
  color: #ffffff;
}
</style>