export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cil-speedometer',
        badge: {
          color: 'primary',
          text: 'NEW'
        }
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['User']
      },
        {
        _name: 'CSidebarNavDropdown',
        name: 'Details',
        icon: 'cil-list',
        items: [
          {
            name: 'Plans',
            to: '/plans',
            icon: 'cil-chart-pie'
          },{
            name: 'History',
            to: '/history',
            icon: 'cil-star',
          }
        ]
      },
    ]
  }
]